import {
  Box,
  Button,
  Card,
  Loader,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  WixDesignSystemProvider,
  Image,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import HeroImage from "./hero.png";
import { ReactTagManager } from "react-gtm-ts";
import * as Icons from "@wix/wix-ui-icons-common";
import { useTranslation } from "react-i18next";
import i18n from './i18n';
const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  const { t } = useTranslation()
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=27fcc256-f3f8-47df-a66a-8f8176cc7f99&redirectUrl=https://certifiedcode.wixsite.com/tiktok-embed/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t('loading')} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="840px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title={t('tiktok-title')}
                content={t('tiktok-content')}
                actions={
                  <Box direction="horizontal" gap="SP1">
                    {/* <Button onClick={() => { window.Intercom("showArticle", 8100023) }}>See how</Button> */}
                    <Button
                      prefixIcon={<Icons.Reviews />}
                      skin="inverted"
                      as="a"
                      href="https://bridget.reviews.certifiedcode.us/27fcc256-f3f8-47df-a66a-8f8176cc7f99"
                      target="_blank"
                    >
                      {t('tiktok-review-button')}
                    </Button>
                  </Box>
                }
              />
            </Box>
          }
          image={<Image borderRadius={0} src={HeroImage} />}
        />
      </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
